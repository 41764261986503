<template>
  <FancyBox
    class="pa-4"
    :options="{
           Carousel: {
           infinite: true
          },
           Thumbs: {
           showOnStart: true
          }
        }"
  >
    <div
      v-if="imageList.length > 0"
      class="d-flex flex-wrap images pl-1"
    >
      <v-card
        v-for="item in imageList"
        :key="item.id"
        max-width="250"
        class="pa-2 align-center d-flex flex-column"
      >
        <a
          data-fancybox="gallery"
          :data-src="item.src"
        >
          <v-img
            max-height="100"
            max-width="250"
            min-height="150"
            contain
            style="cursor: pointer"
            :src="item.thumbnail"
            lazy-src="@/assets/gray.png"
          ></v-img>
        </a>
      </v-card>
    </div>
  </FancyBox>
  <!--  <div>
      <v-carousel
        v-model="currentId"
        hide-delimiters
        show-arrows
      >
        <v-carousel-item
          v-for="(item,i) in imageList"
          :key="i"
          :src="item.src"
          @click="openImage(item)"
        ></v-carousel-item>
      </v-carousel>
      <v-slide-group
        v-model="currentId"
        class="pa-4"
        active-class="success"
        center-active
      >
        <v-slide-item
          v-for="(item, index) in imageList"
          :key="index"
          v-slot="{ active }"
        >
          <v-img
            :src="item.src"
            height="100"
            width="100"
            max-width="100"
            class="mr-3 ml-3"
            :class="imageClass(active)"
            @click="currentId = item.id"
          >
          </v-img>
        </v-slide-item>
      </v-slide-group>
      <Dialog
        withoutIndents
        :value="isOpenDialog"
        is-cross-close
        @input="isOpenDialog = false"
        :max-width="1000"
        :is-full-screen="widthWindow < 1000"
        v-if="openedImage"
      >
        <template #content>
          <v-carousel
            v-model="currentId"
            hide-delimiters
            :show-arrows-on-hover="true"
          >
            <v-carousel-item
              v-for="(item,i) in imageList"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
        </template>
      </Dialog>
    </div>-->
</template>

<script>
/*import Dialog from './Dialog'*/
import FancyBox from '@/components/base/FancyBox'

export default {
  name: 'ViewImages',
  components: { FancyBox },
  props: {
    imageList: Array
  }
  /*  data () {
      return {
        currentId: null,
        openedImage: null,
        isOpenDialog: false,
        widthWindow: window.innerWidth
      }
    },
    methods: {
      imageClass (active) {
        let imageCLass = 'no-active-image'
        if (active) {
          imageCLass = 'active-image'
        }
        return imageCLass
      },
      openImage (imageObject) {
        this.isOpenDialog = true
        this.openedImage = imageObject
      }
    }*/
}
</script>

<style scoped>
  .description-image-textarea textarea {
    max-height: 70px;
    line-clamp: 5; /* максимальное количество отображаемых строк */
    -webkit-line-clamp: 5;
    word-break: break-word;
    overflow: hidden;
    line-height: 1.2;
    text-overflow: ellipsis;
    margin: 8px 0;
    min-width: 180px;
  }
  .content-images {
    height: calc(100vh - 75px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .images {
    gap: 20px 10px;
  }
  .description-image {
    max-height: 76px;
    line-clamp: 5; /* максимальное количество отображаемых строк */
    -webkit-line-clamp: 5;
    word-break: break-word;
    overflow: hidden;
    line-height: 1.2;
    text-overflow: ellipsis;
  }
  .active-image {
    border: 5px solid cornflowerblue;
  }
  .no-active-image {
    opacity: 0.6;
  }
</style>
