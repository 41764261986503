<template>
  <div class="section section-details">
    <Progress
      v-if="isLoading"
    />
    <div v-else>
      <!--todo: стилизовать блок с ошибкой-->
      <div v-if="!detail">
        Что-то пошло не так
      </div>
      <v-container
        v-else
        fluid
        class="grid_list"
      >
        <vue-headful :title="formatTitleAdvert"/>
        <v-card
          outlined
          class="box-1 rounded-xl"
        >
            <ViewImages :imageList="imageList"/>
        </v-card>

        <v-card
          outlined
          class="rounded-xl pr-2 pl-2"
          :min-width="width >= 1400 ? 430 : width >= 1000 ? 370 : 'auto'"
        >
          <p
            style="color: #3FD345 !important"
            class="ma-0 pa-5 green--text text-h4 d-flex justify-space-between pb-0 pt-12 weight-900"
          >
            {{ formatPrice(detail.price * 1000) }}
            <FavoritesBtn :item="detail"/>
          </p>
          <span
            style="font-size: 12px;"
            class="pa-5 pt-0"
          >актуально на: {{ formatTodayDate(detail.updated_at) }} </span>

          <v-card-title class="detail-head-title text-h5 mt-7 weight-900 text-uppercase pt-0">
            {{ formatTitleAdvert }}
          </v-card-title>

          <v-card-text class="d-flex flex-column">
            <div
              type="button"
              @click="copy(fullUrl, 'Ссылка скопирована!')"
              class="black--text ma-2 border-dashed v-card__title text-h6 text-center justify-center mt-0 weight-900 light-grey-hover"
            >
              <v-icon
                class="pr-1"
                color="black"
              >mdi-content-copy
              </v-icon>
              ID {{ detail.id }}
            </div>
            <a
              :href="isMobile ? 'tel:+79094078581' : '#'"
              @click="!isMobile && copy('+79094078581')"
              class="black--text ma-2 border-dashed v-card__title text-h6 text-center justify-center mb-6 weight-700 light-grey-hover"
            >
              <v-icon
                class="pr-1"
                color="black"
              >mdi-phone-outline
              </v-icon>
              +7 909 407-85-81
            </a>
            <item-info-advert
              left="Адрес"
              :right="detail.building.mainBuildingAddress.street.name + ' ' + detail.building.mainBuildingAddress.building_number"
            />
            <item-info-advert
              left="Дом"
              :right="detail.building.is_ready ? 'Сдан' : 'Строится'"
            />
            <item-info-advert
              left="Год постройки"
              :right="detail.building.build_year"
            />
            <item-info-advert
              left="Этаж/Этажность"
              :right="detail.floor + '/' +
                    (detail.building.total_floors ?
                      detail.building.total_floors :
                      detail.total_floors)"
            />
            <item-info-advert
              left="Материал стен"
              :right="formatValue('building.building_material', detail.building.building_material)"
            />
            <item-info-advert
              left="ЖК"
              v-if="detail.building.apartment_complex"
              :right="formatValue('building.apartment_complex',detail.building.apartment_complex)"
            />
            <item-info-advert
              left="Застройщик"
              v-if="detail.building.builder"
              :right="formatValue('building.builder', detail.building.builder)"
            />
            <item-info-advert
              v-if="detail.area_common !== '0.00'"
              left="Площадь"
              :right="detail.area_common"
            />
            <item-info-advert
              v-if="detail.area_living !== '0.00'"
              left="Жилая площадь"
              :right="detail.area_living"
            />
            <item-info-advert
              v-if="detail.area_kitchen !== '0.00'"
              left="Кухня"
              :right="detail.area_kitchen"
            />
            <item-info-advert
              left="Тип жилья"
              :right="formatValue('advert.flat_type', detail.flat_type)"
            />
            <item-info-advert
              left="Состояние"
              :right="formatValue('advert.flat_status', detail.flat_status)"
            />
            <item-info-advert
              v-if="detail.planing_type"
              left="Планировка"
              :right="detail.planing_type"
            />
            <p class="pa-2 ma-0 black--text subtitle-1 mt-6">
              {{ detail.description_public }}
            </p>
          </v-card-text>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import Progress from '@/components/views/Sale/Common/Progress'
import detail from '@/components/mixins/detail'
import { defaultImageUrl } from '@/config'
import FavoritesBtn from '@/components/views/Sale/Common/FavoritesBtn'
import image from '@/components/mixins/image'
import ItemInfoAdvert from '@/components/views/Sale/Detail/ItemInfoAdvert'
import isMobile from '@/components/mixins/isMobile'
import ViewImages from '../../base/ViewImages'
import formatTitleAdvert from '@/components/mixins/formatTitleAdvert'

export default {
  mixins: [detail, image, isMobile, formatTitleAdvert],
  name: 'DetailSale',
  components: { ItemInfoAdvert, FavoritesBtn, Progress, ViewImages },
  data: () => ({
    currentId: null,
    url: '/sale'
  }),
  computed: {
    imageList () {
      let images = []
      if (Object.keys(this.detail).length) {
        const firstImage = this.detail.building.buildingImages.find(image => image.is_main)
        if (firstImage) {
          images.push(firstImage) // ставим главную фотку здания первой
        }
        images = images.concat(this.detail.building.buildingImages.filter(image => !image.is_main)) // далее заполняем массив
        images = images.concat(this.detail.advertImages)
        if (images.length) {
          images = images.map((image, index) => {
            return {
              id: index,
              src: this.imageSrc(image.image),
              thumbnail: this.thumbSrc(image.image),
              alt: 'Some alt text'
            }
          })
        } else {
          images.push({
            id: '1',
            src: defaultImageUrl,
            thumbnail: defaultImageUrl,
            alt: 'Another alt text'
          })
        }
      }
      return images
    },
    fullUrl () {
      return document.URL
    }
  },
  methods: {
    handleClose () {
      this.$router.back()
    },
    copy (text, message) {
      navigator.clipboard.writeText(text)
      this.$store.dispatch('systemMessages/success', message || 'Скопировано!')
    }
  },
  mounted () {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }
}
</script>
<style>
  .lingallery {
    width: 100% !important;
  }
  .lingallery figure {
    width: 100% !important;
  }
</style>

<style scoped>
  .grid_list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 14px;
  }
  .box-1 {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  @media (max-width: 1000px) {
    .grid_list {
      grid-template-columns: 1fr;
      justify-content: center;
      column-gap: 0;
    }
  }
  .detail-title h1 {
    font-size: 20px;
  }
  .section-details {
    min-height: calc(100vh - 48px);
    padding-right: 12px;
    padding-left: 12px;
  }
  .text p, .text div {
    font-size: 16px;
    line-height: 1.5;
  }
</style>
