<template>
  <div ref="container">
    <slot></slot>
  </div>
</template>

<script>
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import ru from '@/messages/ru/fancybox.js'

export default {
  name: 'FancyBox',
  props: {
    options: Object
  },
  mounted () {
    Fancybox.bind(this.$refs.container, '[data-fancybox]', {
      l10n: ru,
      ...(this.options || {})
    })
  },
  updated () {
    Fancybox.unbind(this.$refs.container)
    Fancybox.close()

    Fancybox.bind(this.$refs.container, '[data-fancybox]', {
      l10n: ru,
      ...(this.options || {})
    })
  },
  unmounted () {
    Fancybox.destroy()
  }
}
</script>

<style>
  .fancybox__caption {
    font-size: 24px;
  }
</style>
